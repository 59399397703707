/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="65"
         fill="none"
         viewBox="0 0 64 65">
         <rect
            width="64"
            height="64"
            y="0.65"
            fill="#3B5235"
            rx="32"></rect>
         <path
            fill="#F5F5F5"
            d="M41.6 44.65c.637 0 1.247-.281 1.697-.781.45-.5.703-1.178.703-1.886v-6.512c0-.707-.253-1.385-.703-1.885-.45-.5-1.06-.781-1.697-.781h-3.331c0-.937.05-1.874.149-2.811.148-.992.397-1.874.744-2.645.347-.772.794-1.38 1.341-1.822.544-.496 1.239-.744 2.083-.744V20.65c-1.388 0-2.604.33-3.648.992a8.3 8.3 0 00-2.604 2.645 13.878 13.878 0 00-1.487 3.888 22.723 22.723 0 00-.447 4.63v9.178c0 .708.253 1.386.703 1.886.45.5 1.06.78 1.697.78h4.8zm-14.4 0c.637 0 1.247-.281 1.697-.781.45-.5.703-1.178.703-1.886v-6.512c0-.707-.253-1.385-.703-1.885-.45-.5-1.06-.781-1.697-.781h-3.33c0-.937.049-1.874.148-2.811.15-.992.398-1.874.744-2.645.347-.772.794-1.38 1.341-1.822.544-.496 1.239-.744 2.084-.744V20.65c-1.39 0-2.605.33-3.648.992a8.3 8.3 0 00-2.604 2.645 13.878 13.878 0 00-1.488 3.888 22.737 22.737 0 00-.447 4.63v9.178c0 .708.253 1.386.703 1.886.45.5 1.06.78 1.697.78h4.8z"></path>
      </svg>
   );
}

export default Icon;
