/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="213"
         height="750"
         fill="none"
         viewBox="0 0 213 750">
         <path
            fill="#EBEBEB"
            d="M209.508 749.657H.618c-3.61-.006-7.07-1.715-9.624-4.751-2.553-3.037-3.99-7.154-3.994-11.448V16.664c.005-4.294 1.441-8.411 3.994-11.448C-6.453 2.18-2.992.471.62.466H23.26c9.653 2.794 18.054 13.9 20.429 26.009 3.183 16.25-1.269 33.086-5.61 48.96-4.34 15.865-8.665 32.793-5.226 48.971 9.95 46.956 73.909 52.322 84.506 99.065 6.205 27.376-9.585 54.287-24.48 76.428-14.888 22.141-30.737 48.961-24.65 76.377 4.46 20.076 19.466 33.784 32.651 47.391 13.185 13.608 26.318 31.466 24.795 52.15-1.592 21.656-18.675 37.409-23.203 58.467-5.942 27.69 11.465 55.066 31.17 71.417 19.705 16.34 43.061 27.203 59.157 48.403 19.228 25.321 24.038 62.335 16.709 95.553z"></path>
      </svg>
   );
}

export default Icon;
