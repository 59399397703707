/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="80%"
         height="100%"
         fill="none"
         viewBox="0 0 445 382">
         <g clipPath="url(#clip0_543_5699)">
            <path
               fill="#3B5235"
               d="M342.011 266.327a3.667 3.667 0 01-2.507-.994 3.665 3.665 0 01-1.152-2.439l-.546-8.322a3.677 3.677 0 013.426-3.906l65.204-4.27a7.842 7.842 0 013.983 14.863 7.837 7.837 0 01-2.958.791l-65.204 4.269a3.368 3.368 0 01-.246.008z"></path>
            <path
               fill="#2F2E41"
               d="M354.869 267.401a3.68 3.68 0 01-3.669-3.537l-.465-12.431a3.672 3.672 0 013.533-3.807l53.62-2.004a3.672 3.672 0 013.808 3.533l.464 12.431a3.669 3.669 0 01-2.139 3.476 3.676 3.676 0 01-1.394.332l-53.62 2.004a3.177 3.177 0 01-.138.003zM417.702 381.99h-8.341a3.675 3.675 0 01-3.673-3.673v-69.514a3.678 3.678 0 013.673-3.673h8.341a3.675 3.675 0 013.673 3.673v69.514a3.675 3.675 0 01-3.673 3.673zM397.778 381.99h-8.34a3.674 3.674 0 01-3.673-3.673v-69.514a3.674 3.674 0 013.673-3.673h8.34a3.674 3.674 0 013.673 3.673v69.514a3.675 3.675 0 01-3.673 3.673z"></path>
            <path
               fill="#3B5235"
               d="M405.034 232.584c15.917 0 28.82-12.903 28.82-28.819 0-15.917-12.903-28.82-28.82-28.82-15.916 0-28.819 12.903-28.819 28.82 0 15.916 12.903 28.819 28.819 28.819z"></path>
            <path
               fill="#2F2E41"
               d="M389.434 213.605a6.843 6.843 0 01-2.496-1.466 4.597 4.597 0 01-1.343-3.638 3.095 3.095 0 011.375-2.38c1.03-.661 2.409-.663 3.812-.044l-.053-11.251 1.13-.006.063 13.227-.871-.548c-1.01-.634-2.452-1.08-3.471-.427-.252.173-.46.402-.61.668a1.996 1.996 0 00-.25.869 3.48 3.48 0 00.999 2.713c1.248 1.192 3.069 1.565 5.145 1.898l-.179 1.116a18.663 18.663 0 01-3.251-.731zM379.079 194.259l-.148 1.12 6.034.797.148-1.12-6.034-.797zM398.126 196.772l-.148 1.121 6.034.797.148-1.121-6.034-.797zM423.827 327.454h-43.326a4.816 4.816 0 01-4.664-3.651 4.72 4.72 0 01-.063-1.741s8.463-47.524 12.65-70.349a14.456 14.456 0 0114.223-11.849 31.256 31.256 0 0131.255 31.255v46.259a10.082 10.082 0 01-2.951 7.125 10.077 10.077 0 01-7.124 2.951z"></path>
            <path
               fill="#3B5235"
               d="M415.472 321.174a3.683 3.683 0 01-3.442 2.032l-8.333-.351a3.69 3.69 0 01-2.55-1.184 3.672 3.672 0 01-.966-2.641l2.751-65.286a7.848 7.848 0 012.528-5.444 7.85 7.85 0 015.638-2.062 7.84 7.84 0 017.506 8.166l-2.75 65.286a3.65 3.65 0 01-.382 1.484z"></path>
            <path
               fill="#2F2E41"
               d="M419.737 303.863a3.68 3.68 0 01-3.442 2.032l-14.233-.599a3.676 3.676 0 01-3.515-3.825l2.258-53.609a3.674 3.674 0 012.408-3.295 3.653 3.653 0 011.416-.22l14.234.599a3.69 3.69 0 012.549 1.184 3.673 3.673 0 01.966 2.641l-2.258 53.609a3.659 3.659 0 01-.383 1.483zM403.432 185.045c-6.216 2.451-15.609 5.057-19.707-2.221a11.882 11.882 0 01-.675-9.772c1.238-3.434 3.871-6.262 6.872-8.261 5.621-3.745 12.732-4.764 19.279-3.317a26.32 26.32 0 019.2 3.954c3.166 2.171 5.396 5.101 7.637 8.167 2.291 3.136 4.885 6.298 8.65 7.671 3.078 1.123 6.938.55 9.116-2.063.508-.61.899-1.31 1.15-2.063.327-.961-1.192-1.373-1.516-.419-1.145 3.363-5.358 4.117-8.332 3.028-3.927-1.437-6.407-5.17-8.762-8.394-2.251-3.081-4.74-5.794-7.991-7.838a27.751 27.751 0 00-9.546-3.726c-6.815-1.297-14.154-.148-19.93 3.811a19.253 19.253 0 00-7.167 8.566 13.574 13.574 0 00.094 10.299 10.938 10.938 0 007.916 6.451c3.808.771 7.765-.116 11.379-1.34a60.83 60.83 0 002.752-1.016c.93-.368.525-1.889-.419-1.517z"></path>
            <path
               fill="#F2F2F2"
               d="M25.623.66h197.305a25.26 25.26 0 0125.233 25.232v107.942a25.26 25.26 0 01-25.233 25.232H25.623A25.26 25.26 0 01.39 133.834V25.892A25.26 25.26 0 0125.623.66z"></path>
            <path
               fill="#fff"
               d="M25.622 7.924h197.306a17.988 17.988 0 0117.969 17.968v107.942a17.987 17.987 0 01-17.969 17.968H25.623a17.989 17.989 0 01-17.969-17.968V25.892A17.989 17.989 0 0125.622 7.924z"></path>
            <path
               fill="#F2F2F2"
               d="M27.548 197.713a4.527 4.527 0 01-1.734-.351 4.381 4.381 0 01-2.751-4.117V155.93l49.747-1.673-42.14 42.139a4.385 4.385 0 01-3.122 1.317zM198.217 34.773H49.853c-5.94 0-10.755 4.815-10.755 10.755s4.815 10.756 10.755 10.756h148.364c5.94 0 10.756-4.816 10.756-10.756s-4.816-10.755-10.756-10.755zM198.458 69.108H50.094c-5.94 0-10.756 4.815-10.756 10.755s4.816 10.755 10.756 10.755h148.364c5.94 0 10.755-4.815 10.755-10.755s-4.815-10.755-10.755-10.755zM198.698 103.442H50.334c-5.94 0-10.755 4.816-10.755 10.756s4.815 10.755 10.755 10.755h148.364c5.94 0 10.755-4.815 10.755-10.755s-4.815-10.756-10.755-10.756z"></path>
            <path
               fill="#E6E6E6"
               d="M337.183 55.712H139.878a25.26 25.26 0 00-25.232 25.232v107.942a25.26 25.26 0 0025.232 25.232h197.305a25.26 25.26 0 0025.233-25.232V80.944a25.264 25.264 0 00-25.233-25.232z"></path>
            <path
               fill="#fff"
               d="M337.184 62.976H139.878a17.99 17.99 0 00-17.969 17.968v107.942a17.99 17.99 0 0017.969 17.968h197.306a17.986 17.986 0 0017.968-17.968V80.944a17.986 17.986 0 00-17.968-17.968z"></path>
            <path
               fill="#E6E6E6"
               d="M322.174 251.448l-42.14-42.139 49.748 1.673v37.315a4.39 4.39 0 01-2.751 4.117c-.55.23-1.139.349-1.734.351a4.4 4.4 0 01-3.123-1.317z"></path>
            <path
               fill="#3B5235"
               d="M195.013 148.547c7.529 0 13.632-6.103 13.632-13.632s-6.103-13.632-13.632-13.632c-7.528 0-13.632 6.103-13.632 13.632s6.104 13.632 13.632 13.632zM238.531 148.547c7.528 0 13.632-6.103 13.632-13.632s-6.104-13.632-13.632-13.632c-7.529 0-13.632 6.103-13.632 13.632s6.103 13.632 13.632 13.632zM282.048 148.547c7.529 0 13.632-6.103 13.632-13.632s-6.103-13.632-13.632-13.632-13.632 6.103-13.632 13.632 6.103 13.632 13.632 13.632z"></path>
         </g>
         <defs>
            <clipPath id="clip0_543_5699">
               <path
                  fill="#fff"
                  d="M0 0H444.609V381.33H0z"
                  transform="translate(.39 .66)"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
