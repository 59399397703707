/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="213"
         height="750"
         fill="none"
         viewBox="0 0 213 750">
         <path
            fill="#EBEBEB"
            d="M3.259.334h208.889c3.61.006 7.071 1.715 9.624 4.751 2.553 3.037 3.99 7.154 3.994 11.448v716.794c-.004 4.294-1.441 8.411-3.994 11.447-2.553 3.037-6.014 4.746-9.625 4.751h-22.641c-9.652-2.794-18.054-13.9-20.428-26.009-3.184-16.249 1.268-33.086 5.609-48.96 4.341-15.865 8.665-32.793 5.226-48.971-9.95-46.956-73.908-52.322-84.505-99.066-6.206-27.375 9.584-54.286 24.48-76.427 14.887-22.142 30.736-48.961 24.65-76.377-4.46-20.077-19.467-33.784-32.651-47.391-13.185-13.608-26.32-31.466-24.795-52.15 1.591-21.656 18.675-37.409 23.203-58.468 5.941-27.689-11.466-55.065-31.17-71.416-19.705-16.34-43.062-27.204-59.158-48.404C.74 70.566-4.07 33.552 3.26.334z"></path>
      </svg>
   );
}

export default Icon;
