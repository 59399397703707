/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="80%"
         height="100%"
         fill="none"
         viewBox="0 0 492 382">
         <g clipPath="url(#clip0_543_5339)">
            <path
               fill="#F1F1F1"
               d="M7.558 351.538c3.211 11.139 12.587 18.936 22.366 25.56 1.366.925 2.732 1.828 4.098 2.711.01.004.019.013.028.017a274.188 274.188 0 001.037.67l-.154.069s-.15.08.014.013l.15-.06c5.681-2.291 11.463-4.751 15.817-9.044 4.517-4.457 7.157-11.449 4.71-17.304a11.156 11.156 0 00-1.21-2.173 12.255 12.255 0 00-.634-.823 11.987 11.987 0 00-17.035-1.533 11.993 11.993 0 00-3.548 5.175c-.719-6.69 4.798-12.293 10.106-16.428 5.31-4.132 11.346-8.633 12.372-15.285.574-3.702-.713-7.289-3.058-10.168a17.492 17.492 0 00-11.46-6.291c-8.145-.846-16.055 3.464-21.97 9.126-9.523 9.11-15.279 23.103-11.629 35.768z"></path>
            <path
               fill="#fff"
               d="M22.865 336.968a29.735 29.735 0 00-3.562 7.856 25.82 25.82 0 00-.892 7.625 33.072 33.072 0 004.332 15.052 47.612 47.612 0 007.181 9.597c1.366.925 2.732 1.828 4.098 2.711.01.004.019.013.028.017.066.042.132.084.193.127l.844.543s-.305.149-.14.082l.15-.06a26.318 26.318 0 018.42-22.657 26.532 26.532 0 0110.897-5.864 12.222 12.222 0 00-.634-.823 27.583 27.583 0 00-4.542 1.719 26.96 26.96 0 00-12.191 11.754 27.564 27.564 0 00-3.002 15.127 48.937 48.937 0 01-.694-.642c-4.317-4.025-8.151-8.645-10.746-13.977a30.77 30.77 0 01-3.223-14.559c.212-5.499 2.403-10.594 5.576-15.028a64.411 64.411 0 0112.21-12.583 73.18 73.18 0 0115.184-9.375.525.525 0 00.265-.675.447.447 0 00-.22-.26.4.4 0 00-.17-.032.408.408 0 00-.168.046c-.67.309-1.335.621-1.995.947a74.043 74.043 0 00-15.293 10.088c-4.502 3.838-8.74 8.223-11.906 13.244z"></path>
            <path
               fill="#FFB7B7"
               d="M151.429 372.118h8.533l4.058-32.908h-12.592l.001 32.908z"></path>
            <path
               fill="#2F2E41"
               d="M149.253 369.333l16.802-.001h.001a10.707 10.707 0 0110.708 10.708v.348l-27.51.001-.001-11.056z"></path>
            <path
               fill="#FFB7B7"
               d="M105.235 372.118h8.532l4.059-32.908h-12.592l.001 32.908z"></path>
            <path
               fill="#2F2E41"
               d="M103.059 369.333l16.802-.001h.001a10.707 10.707 0 0110.708 10.708v.348l-27.511.001v-11.056zM101.759 222.969v86.89l2.2 56.093h18.147l6.05-105.587 12.098 43.445 7.699 61.042 21.998-1.1-14.848-140.783h-53.344z"></path>
            <path
               fill="#FFB8B8"
               d="M122.856 144.878c7.289 0 13.198-5.909 13.198-13.198 0-7.289-5.909-13.198-13.198-13.198-7.29 0-13.199 5.909-13.199 13.198 0 7.289 5.909 13.198 13.199 13.198z"></path>
            <path
               fill="#3B5235"
               d="M117.371 148.317l-3.513 3.71-3.3 74.242 39.045-1.1-9.349-64.892-8.248-7.905-4.186-4.055h-10.449z"></path>
            <path
               fill="#2F2E41"
               d="M111.108 156.427l2.964-4.81.885 10.309s7.149 23.097 6.6 43.445c-.55 20.348 2.749 68.192-1.65 68.742-4.4.55-23.097 1.649-23.097-2.2 0-3.85-1.1-106.137-1.1-106.137l15.398-9.349zM131.12 151.617l7.484 5.91 16.498 6.049s0 70.392 3.3 86.889c3.3 16.498 9.349 18.148 1.65 18.698-7.699.55-7.149-2.199-7.149-2.199s-22.548-70.392-21.448-87.99c1.1-17.598-1.435-27.357-.335-27.357zM110.369 135.809s1.121-1.969.861-8.548c-.261-6.579 9.757-2.858 18.128-3.485 8.371-.628 6.599 10.983 6.599 10.983s.604-.69 1.897-6.294c1.293-5.604-1.643-11.188-1.643-11.188-.864-5.889-5.524-4.002-5.524-4.002 2.244.874 1.813 2.742 1.813 2.742-1.899-3.33-6.559-1.443-6.559-1.443-6.215-3.9-12.514 1.236-12.514 1.236-7.507 1.704-3.104 7.674-3.104 7.674-7.551 3.816.046 12.325.046 12.325z"></path>
            <path
               fill="#FFB7B7"
               d="M96.828 263.961a5.569 5.569 0 00-.987-8.48l2.377-12.498-7.243-3.298-3.11 17.698a5.597 5.597 0 008.963 6.578z"></path>
            <path
               fill="#2F2E41"
               d="M98.46 165.776l-1.46-.783s-7.89 2.983-7.34 5.182c.55 2.2-4.4 79.191-2.75 81.94 1.65 2.75 14.849.55 14.849.55l-3.3-86.889z"></path>
            <path
               fill="#FFB7B7"
               d="M161.239 258.778a5.561 5.561 0 01-2.09-4.222 5.566 5.566 0 011.894-4.313l-4.084-12.05 6.718-4.267 5.528 17.097a5.6 5.6 0 01-.539 7.415 5.594 5.594 0 01-7.427.34z"></path>
            <path
               fill="#2F2E41"
               d="M154.792 248.273s13.376.352 14.63-2.599c1.253-2.952-14.301-78.517-14.061-80.772.241-2.254-7.985-4.117-7.985-4.117l-1.338.977 8.754 86.511z"></path>
            <path
               fill="#CBCBCB"
               d="M242.78 381.66H.636a.636.636 0 010-1.271H242.78a.638.638 0 01.636.635.636.636 0 01-.636.636z"></path>
            <path
               fill="#E5E5E5"
               d="M491.343 196.079H267.847V56.938h223.496v139.141zm-218.296-5.2h213.096V62.139H273.047v128.74z"></path>
            <path
               fill="#fff"
               d="M488.743 59.538H270.447V193.48h218.296V59.539z"></path>
            <path
               fill="#E6E6E6"
               d="M488.743 93.485V85.26h-30.282V59.538h-8.224v25.723h-53.913V59.538H388.1v25.723h-83.153V59.538h-8.224v25.723h-26.276v8.224h26.276v31.982h-26.276v8.224h26.276v34.266h-26.276v8.224h70.594v17.298h8.224v-17.298H388.1v17.298h8.224v-17.298h92.419v-8.224h-92.419v-34.266h92.419v-8.224h-30.282V93.485h30.282zm-100.643 0v10.051h-83.153V93.485H388.1zm-83.153 18.275H388.1v13.707h-83.153V111.76zm0 56.197v-34.266h36.094v34.266h-36.094zm83.153 0h-38.835v-34.266H388.1v34.266zm62.137-42.49h-53.913V93.485h53.913v31.982z"></path>
            <path
               fill="#3B5235"
               d="M431.886 39.764c0 21.78-39.434 70.296-39.434 70.296s-39.434-48.517-39.434-70.296A39.431 39.431 0 01392.452.33a39.432 39.432 0 0139.434 39.434z"></path>
            <path
               fill="#fff"
               d="M410.48 38.05a18.03 18.03 0 01-30.774 12.747 18.024 18.024 0 012.731-27.736 18.03 18.03 0 0116.916-1.668A18.022 18.022 0 01410.48 38.05z"></path>
            <path
               fill="#3B5235"
               d="M392.452 138.521c5.871 0 10.63-4.759 10.63-10.63s-4.759-10.63-10.63-10.63-10.63 4.759-10.63 10.63 4.759 10.63 10.63 10.63z"></path>
         </g>
         <defs>
            <clipPath id="clip0_543_5339">
               <path
                  fill="#fff"
                  d="M0 0H491.343V381.33H0z"
                  transform="translate(0 .33)"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
