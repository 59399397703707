/** @format */

import React from "react";
import SectionTitle from "../SectionTitle";
import Quote from "assets/icons/Quote";

export default function Services() {
   return (
      <div
         id="testimonials"
         className=" mt-4 lg:h-[80vh]">
         <SectionTitle title={"Testimonials"} />
         <div className="lg:h-[80%]  lg:w-full items-center mt-4 py-4 flex lg:flex-row flex-col justify-center gap-5">
            <div
               data-aos="flip-up"
               className="lg:w-[47%] w-[90%]  lg:h-[100%]  border-[1px] border-solid border-[#c4c4ca]  rounded-[1.5rem]">
               <img
                  className="w-full h-full overflow-hidden object-cover rounded-[1.5rem]"
                  src={require("../../../assets/images/testimonial.png")}
               />
            </div>
            <div
               data-aos="fade"
               className="lg:w-[47%]  p-2 items-center lg:items-start flex-col flex lg:gap-3 gap-4 justify-between h-full">
               <Quote />
               <p className="lg:w-[90%] w-[95%] font-sans  text-[1.6rem]">
                  It was nice working with Akalya. Big plus on her drive to complete the project even though we had fluid deadlines. Appreciated all the questions to clarify data points as we had a
                  complex task.
               </p>
               <div className="flex  items-start">
                  <div>
                     <h3 className="text-[2rem] font-bold">Zoltan Jakab</h3>
                     <p className="text-[1.5rem]">Games Bond Pte Ltd</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
