/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="65"
         fill="none"
         viewBox="0 0 64 65">
         <rect
            width="64"
            height="64"
            y="0.495"
            fill="#3B5235"
            rx="32"></rect>
         <g clipPath="url(#clip0_543_5318)">
            <path
               fill="#FAFAFA"
               d="M16 32.495a16 16 0 1132 0 16 16 0 01-32 0zm15-13.846c-1.34.408-2.67 1.64-3.774 3.71a16.004 16.004 0 00-.936 2.136H31v-5.846zm-6.82 5.846a18.609 18.609 0 011.28-3.078c.348-.653.747-1.277 1.194-1.866a14.06 14.06 0 00-6.144 4.944h3.67zm-1.164 7c.06-1.754.276-3.436.624-5h-4.292a14 14 0 00-1.312 5h4.98zm2.678-5a24.995 24.995 0 00-.676 5H31v-5h-5.306zm7.306 0v5h5.98a25.001 25.001 0 00-.674-5H33zm-7.98 7a25 25 0 00.674 5H31v-5h-5.98zm7.98 0v5h5.306c.374-1.53.612-3.216.676-5H33zm-6.71 7c.277.773.59 1.485.936 2.136 1.104 2.07 2.436 3.3 3.774 3.71v-5.846h-4.71zm.364 4.944a13.996 13.996 0 01-1.194-1.866 18.602 18.602 0 01-1.28-3.078h-3.67a14 14 0 006.144 4.944zm-3.014-6.944a27.403 27.403 0 01-.624-5h-4.98c.124 1.78.582 3.466 1.312 5h4.292zm13.706 6.944a14 14 0 006.144-4.944h-3.67a18.602 18.602 0 01-1.28 3.078 13.996 13.996 0 01-1.194 1.866zM33 40.495v5.846c1.34-.408 2.67-1.64 3.774-3.71.347-.65.659-1.361.936-2.136H33zm7.36-2h4.292a13.853 13.853 0 001.312-5h-4.98a27.403 27.403 0 01-.624 5zm5.604-7a14 14 0 00-1.312-5H40.36c.348 1.564.564 3.246.624 5h4.98zM38.54 21.417c.494.928.924 1.96 1.28 3.078h3.67a14 14 0 00-6.144-4.944c.436.568.836 1.196 1.194 1.866zm-.83 3.078a16.004 16.004 0 00-.936-2.136c-1.104-2.07-2.434-3.3-3.774-3.71v5.846h4.71z"></path>
         </g>
         <defs>
            <clipPath id="clip0_543_5318">
               <path
                  fill="#fff"
                  d="M0 0H32V32H0z"
                  transform="translate(16 16.495)"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
