/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="80%"
         height="100%"
         fill="none"
         viewBox="0 0 506 382">
         <g clipPath="url(#clip0_543_5577)">
            <path
               fill="#2F2E41"
               d="M291.95 18.56c4.333 9.22-4.849 11.832-15.367 16.775-10.517 4.942-18.388 10.343-22.721 1.122-4.333-9.22-7.849-27.523 7.03-34.515 15.392-7.232 26.725 7.396 31.058 16.617z"></path>
            <path
               fill="#FFB8B8"
               d="M270.491 36.693c7.819 0 14.159-6.339 14.159-14.159s-6.34-14.159-14.159-14.159c-7.82 0-14.159 6.34-14.159 14.16s6.339 14.158 14.159 14.158z"></path>
            <path
               fill="#2F2E41"
               d="M340.637 85.327a87.62 87.62 0 00-3.407-4.191c-7.338-8.469-15.144-13.801-23.739-14.372h-.012l-.127-.011-17.127 16.695-5.823 5.672.127.312 3.142 7.823h55.049a135.47 135.47 0 00-8.083-11.928zM255.094 28.235l5.068-2.382-.706-7.312 3.389 6.051 2.423-1.138-.412-4.265 1.977 3.53 18.001-8.46a14.992 14.992 0 00-19.94-7.19l-2.609 1.226a14.99 14.99 0 00-7.191 19.94z"></path>
            <path
               fill="#F2F2F2"
               d="M372.402 285.246H76.757a9.521 9.521 0 01-9.512-9.512V98.087a6.976 6.976 0 016.969-6.969h300.611a7.097 7.097 0 017.089 7.09v177.526a9.523 9.523 0 01-9.512 9.512z"></path>
            <path
               fill="#fff"
               d="M85.981 275.734h277.197a9.224 9.224 0 009.224-9.224V115.731a9.224 9.224 0 00-9.224-9.224H85.981a9.223 9.223 0 00-9.224 9.224V266.51a9.224 9.224 0 009.224 9.224z"></path>
            <path
               fill="#3B5235"
               d="M381.781 97.543H67.111V92.7a9.554 9.554 0 019.547-9.54h295.577a9.555 9.555 0 019.546 9.54v4.843z"></path>
            <path
               fill="#fff"
               d="M84.462 92.835a2.47 2.47 0 100-4.939 2.47 2.47 0 000 4.939zM93.834 92.835a2.47 2.47 0 100-4.939 2.47 2.47 0 000 4.939zM103.207 92.835a2.47 2.47 0 100-4.94 2.47 2.47 0 000 4.94z"></path>
            <path
               fill="#CCC"
               d="M233.229 243.035H208.44a5.489 5.489 0 01-3.871-1.606 5.489 5.489 0 01-1.606-3.871v-24.789a5.489 5.489 0 011.606-3.871 5.489 5.489 0 013.871-1.606h24.789a5.489 5.489 0 013.871 1.606 5.489 5.489 0 011.606 3.871v24.789a5.489 5.489 0 01-1.606 3.871 5.489 5.489 0 01-3.871 1.606zm-24.789-34.59a4.336 4.336 0 00-3.056 1.268 4.336 4.336 0 00-1.268 3.056v24.789a4.336 4.336 0 001.268 3.056 4.336 4.336 0 003.056 1.268h24.789a4.336 4.336 0 003.056-1.268 4.336 4.336 0 001.268-3.056v-24.789a4.336 4.336 0 00-1.268-3.056 4.336 4.336 0 00-3.056-1.268H208.44z"></path>
            <path
               fill="#CCC"
               d="M228.329 236.117H213.34a1.734 1.734 0 01-1.729-1.73V215.94a1.728 1.728 0 011.729-1.73h10.861c.394.001.776.135 1.083.382l4.128 3.317a1.712 1.712 0 01.646 1.348v15.13a1.732 1.732 0 01-1.729 1.73zm-14.989-20.754a.575.575 0 00-.576.577v18.447a.578.578 0 00.576.577h14.989a.575.575 0 00.576-.577v-15.13a.582.582 0 00-.215-.45l-4.128-3.317a.58.58 0 00-.361-.127H213.34z"></path>
            <path
               fill="#CCC"
               d="M225.446 222.281h-9.224a.577.577 0 010-1.153h9.224a.578.578 0 010 1.153zM225.446 225.74h-9.224a.577.577 0 010-1.153h9.224a.578.578 0 010 1.153zM225.446 229.199h-9.224a.577.577 0 010-1.153h9.224a.578.578 0 010 1.153zM292.03 243.035h-24.788a5.487 5.487 0 01-5.477-5.477v-24.789a5.484 5.484 0 015.477-5.477h24.788a5.484 5.484 0 015.477 5.477v24.789a5.484 5.484 0 01-5.477 5.477zm-24.788-34.59a4.332 4.332 0 00-4.324 4.324v24.789a4.327 4.327 0 004.324 4.324h24.788a4.332 4.332 0 004.324-4.324v-24.789a4.327 4.327 0 00-4.324-4.324h-24.788z"></path>
            <path
               fill="#CCC"
               d="M287.707 231.505h-16.142a1.734 1.734 0 01-1.729-1.73v-9.223a1.73 1.73 0 011.729-1.73h16.142a1.734 1.734 0 011.729 1.73v9.223a1.73 1.73 0 01-1.729 1.73zm-16.142-11.53a.575.575 0 00-.576.577v9.223a.578.578 0 00.576.577h16.142a.575.575 0 00.576-.577v-9.223a.578.578 0 00-.576-.577h-16.142z"></path>
            <path
               fill="#CCC"
               d="M279.636 226.684a1.728 1.728 0 01-.917-.261l-8.613-5.382a.58.58 0 01-.182-.794.578.578 0 01.794-.184l8.613 5.383a.584.584 0 00.611 0l8.612-5.383a.578.578 0 01.794.184.575.575 0 01-.183.794l-8.612 5.382a1.719 1.719 0 01-.917.261zM174.427 243.035h-24.789a5.484 5.484 0 01-5.476-5.477v-24.789a5.48 5.48 0 015.476-5.477h24.789a5.484 5.484 0 015.477 5.477v24.789a5.484 5.484 0 01-5.477 5.477zm-24.789-34.59a4.33 4.33 0 00-4.323 4.324v24.789a4.33 4.33 0 004.323 4.324h24.789a4.327 4.327 0 004.324-4.324v-24.789a4.327 4.327 0 00-4.324-4.324h-24.789z"></path>
            <path
               fill="#CCC"
               d="M170.103 231.505h-16.141a1.732 1.732 0 01-1.73-1.73v-9.223a1.731 1.731 0 011.73-1.73h16.141a1.735 1.735 0 011.73 1.73v9.223a1.735 1.735 0 01-1.73 1.73zm-16.141-11.53a.58.58 0 00-.577.577v9.223a.58.58 0 00.577.577h16.141a.578.578 0 00.577-.577v-9.223a.578.578 0 00-.577-.577h-16.141z"></path>
            <path
               fill="#CCC"
               d="M167.545 231.505h-11.601a1.147 1.147 0 01-.999-.577 1.153 1.153 0 010-1.153l3.495-6.053a1.154 1.154 0 011.997 0l2.257 3.91 1.307-2.265a1.154 1.154 0 011.998 0l2.545 4.408a1.157 1.157 0 01-.422 1.575 1.15 1.15 0 01-.577.155zm0-1.153L165 225.943l-1.807 3.13a.577.577 0 01-.998 0l-2.756-4.774-3.495 6.053h11.601zM168.374 223.434a1.153 1.153 0 100-2.305 1.153 1.153 0 000 2.305z"></path>
            <path
               fill="#FFB8B8"
               d="M444.058 373.65l-7.067-.001-3.362-27.261 10.431.001-.002 27.261z"></path>
            <path
               fill="#2F2E41"
               d="M445.861 380.5h-22.789v-.289a8.866 8.866 0 015.476-8.194 8.863 8.863 0 013.394-.676h.001l13.918.001v9.158z"></path>
            <path
               fill="#FFB8B8"
               d="M472.883 373.65l-7.068-.001-3.362-27.261 10.431.001-.001 27.261z"></path>
            <path
               fill="#2F2E41"
               d="M474.685 380.5h-22.789v-.289a8.885 8.885 0 012.598-6.272 8.873 8.873 0 016.272-2.598h.001l13.918.001v9.158zM470.764 361.832l-6.546-1.991a2.594 2.594 0 01-1.825-2.21l-6.102-57.837a.278.278 0 00-.276-.258.275.275 0 00-.195.059.265.265 0 00-.099.177l-10.222 56.034a2.603 2.603 0 01-1.101 1.685 2.603 2.603 0 01-1.975.39l-8.365-1.723a2.604 2.604 0 01-2.065-2.716l6.218-91.842 42.163-4.549-6.266 102.457a2.597 2.597 0 01-3.344 2.324z"></path>
            <path
               fill="#FFB8B8"
               d="M470.724 181.621c7.82 0 14.159-6.339 14.159-14.159s-6.339-14.159-14.159-14.159-14.159 6.339-14.159 14.159 6.339 14.159 14.159 14.159z"></path>
            <path
               fill="#3B5235"
               d="M438.146 262.14l9.142-57.563a21.815 21.815 0 019.878-15.015 21.824 21.824 0 0117.796-2.51 21.758 21.758 0 0115.335 24.94l-9.564 51.406-42.587-1.258z"></path>
            <path
               fill="#FFB8B8"
               d="M474.633 272.439a5.81 5.81 0 001.747-1.805 5.807 5.807 0 00.555-4.874 5.797 5.797 0 00-1.295-2.153l9.452-18.304-10.645 1.136-7.344 17.208a5.831 5.831 0 003.495 9.724 5.828 5.828 0 004.035-.932z"></path>
            <path
               fill="#3B5235"
               d="M476.657 260.379a2.594 2.594 0 01-1.399-.664l-3.616-3.341a2.607 2.607 0 01-.63-2.914l7.228-17.132-1.025-33.851a8.35 8.35 0 016.154-6.96 8.353 8.353 0 018.783 3.028 8.356 8.356 0 011.623 6.191l1.753 21.279a26.736 26.736 0 01-5.152 18.1l-11.272 15.238a2.586 2.586 0 01-1.841 1.04 2.614 2.614 0 01-.606-.014z"></path>
            <path
               fill="#FFB8B8"
               d="M389.186 159.747a5.794 5.794 0 007.74 4.371l13.17 15.841 3.105-10.245-12.983-13.472a5.827 5.827 0 00-10.317-.573 5.83 5.83 0 00-.715 4.078z"></path>
            <path
               fill="#3B5235"
               d="M399.503 166.313a2.604 2.604 0 011.156-1.029l4.487-2.027a2.604 2.604 0 012.929.556l12.958 13.335 31.572 12.255a8.35 8.35 0 014.012 8.381 8.363 8.363 0 01-3.27 5.503 8.354 8.354 0 01-9.279.485l-20.279-6.682a26.738 26.738 0 01-14.658-11.802l-9.638-16.321a2.594 2.594 0 01.01-2.654z"></path>
            <path
               fill="#000"
               d="M482.208 216.558l-.576 18.448-10.62 18.454 8.867-20.565 2.329-16.337z"
               opacity="0.1"></path>
            <path
               fill="#2F2E41"
               d="M469.102 170.123a3.703 3.703 0 01-4.053-2.711 5.633 5.633 0 00-4.007-4.193c-2.598-.594-5.772.629-7.675-1.339a3.82 3.82 0 01-.908-3.368c.35-1.912 1.718-3.357 3.207-4.513a19.628 19.628 0 0110.876-4.064 39.027 39.027 0 016.122.304c2.126.135 4.228.519 6.263 1.145a14.748 14.748 0 019.225 10.769 17.027 17.027 0 01-3.492 13.911 18.275 18.275 0 01-7.01 5.337 2.735 2.735 0 01-2.079.106c-1.705-.678-1.426-2.79-.649-4.199.832-1.507 2.112-2.966 1.443-4.801a3.635 3.635 0 00-1.803-1.941c-1.695-.864-3.591-.659-5.413-.449l-.047.006z"></path>
            <path
               fill="#fff"
               d="M384.455 178.118H209.203a9.524 9.524 0 01-9.512-9.512v-37.471a9.524 9.524 0 019.512-9.512h175.252a9.523 9.523 0 019.512 9.512v37.471a9.52 9.52 0 01-9.512 9.512z"></path>
            <path
               fill="#3F3D56"
               d="M384.454 178.407H209.203a9.812 9.812 0 01-9.801-9.8v-37.472a9.811 9.811 0 019.801-9.8h175.251a9.811 9.811 0 019.801 9.8v37.472a9.812 9.812 0 01-9.801 9.8zm-175.251-55.919a8.654 8.654 0 00-8.648 8.647v37.472a8.659 8.659 0 008.648 8.647h175.251a8.66 8.66 0 008.648-8.647v-37.472a8.66 8.66 0 00-8.648-8.647H209.203z"></path>
            <path
               fill="#F2F2F2"
               d="M363.124 168.895H230.533a9.524 9.524 0 01-9.512-9.512v-19.601a9.524 9.524 0 019.512-9.512h132.591a9.524 9.524 0 019.512 9.512v19.601a9.521 9.521 0 01-9.512 9.512z"></path>
            <path
               fill="#FF6584"
               d="M259.066 158.33c6.282 0 11.375-5.093 11.375-11.375 0-6.283-5.093-11.376-11.375-11.376-6.283 0-11.376 5.093-11.376 11.376 0 6.282 5.093 11.375 11.376 11.375z"></path>
            <path
               fill="#3F3D56"
               d="M354.885 167.747l-16.057-13.467-15.779-13.234a2.31 2.31 0 00-2.971.006l-15.728 13.295-3.842 3.247-5.793-4.858-17.604-14.764a2.307 2.307 0 00-2.97.005l-17.547 14.832-17.823 15.066a.579.579 0 00-.17.639.575.575 0 00.543.378l36.541-.071 11.567-.022-.113.096 34.478-.067 32.898-.063a.576.576 0 00.37-1.018z"></path>
            <path
               fill="#CCC"
               d="M505.424 381.33H394.738a.58.58 0 01-.576-.577.576.576 0 01.576-.576h110.686a.577.577 0 010 1.153z"></path>
            <path
               fill="#FFB8B8"
               d="M349.071 76.046a6.457 6.457 0 00-4.179-2.94 5.944 5.944 0 00-.957-.122l-43.923-39.27a5.193 5.193 0 00-3.245-1.55 4.42 4.42 0 00-.732-.024 5.22 5.22 0 00-3.436 8.924l16.816 14.66 27.613 24.069a6.501 6.501 0 002.238 4.624c.996.861 2.23 1.4 3.539 1.544a6.394 6.394 0 003.488-.576 6.575 6.575 0 002.415-1.937 6.512 6.512 0 00.363-7.402zM273.944 113.892l-.409-16.638-.346-13.806-.237-9.662-.467-18.834a5.21 5.21 0 00-3.54-4.47 5.214 5.214 0 00-5.516 1.443 3.96 3.96 0 00-.433.554 5.153 5.153 0 00-.899 3.488l1.026 27.48.513 13.807.657 17.612a7.08 7.08 0 00-.513.813 6.494 6.494 0 00-.409 5.096 6.524 6.524 0 006.309 4.447 6.522 6.522 0 005.106-2.63 6.512 6.512 0 00-.842-8.7z"></path>
            <path
               fill="#CCC"
               d="M313.491 66.764l-4.076-11.04-6.595-17.882a9.23 9.23 0 00-6.053-5.678 9.214 9.214 0 00-7.633 1.049l-22.212 14.089a9.278 9.278 0 00-3.038 12.539 159.473 159.473 0 009.068 13.945c2.589 3.522 7.408 3.897 8.244 9.662h16.378l16.58-14.885-.663-1.799z"></path>
            <path
               fill="#A0616A"
               d="M59.161 201.686c7.378 0 13.358-5.981 13.358-13.358 0-7.378-5.98-13.358-13.358-13.358-7.377 0-13.358 5.98-13.358 13.358 0 7.377 5.98 13.358 13.358 13.358zM91.956 179.133a5.979 5.979 0 012.261 4.85 5.983 5.983 0 01-2.119 4.409c-.15.126-.307.244-.47.354l.905 30.522a4.648 4.648 0 01-3.197 4.535l-.055.012-24.782 2.639a5.125 5.125 0 01-5.064-2.678 5.135 5.135 0 01.575-5.7 5.127 5.127 0 013.496-1.833l17.666-1.556 3.055-26.433a5.614 5.614 0 01-.56-.57 5.985 5.985 0 018.289-8.551zM81.162 374.24h6.668l3.172-25.719h-9.841l.001 25.719z"></path>
            <path
               fill="#2F2E41"
               d="M79.461 372.063h13.132a8.362 8.362 0 018.368 8.368v.272h-21.5v-8.64z"></path>
            <path
               fill="#A0616A"
               d="M14.888 365.021l5.73 3.411L36.5 347.957l-8.456-5.035-13.157 22.099z"></path>
            <path
               fill="#2F2E41"
               d="M14.54 362.28l11.283 6.718a8.365 8.365 0 014 8.394 8.367 8.367 0 01-1.09 3.078l-.14.233-18.473-10.999 4.42-7.424zM89.403 365.156h-7.466a2.44 2.44 0 01-2.434-2.189l-7.527-70.756a.816.816 0 00-1.555-.25l-13.58 30.069-24.04 39.203a2.459 2.459 0 01-3.073.96l-9.604-4.226a2.448 2.448 0 01-1.116-3.496l22.101-37.006 6.778-31.179c-2.132-13.521 7.008-33.624 7.1-33.826l.045-.098 25.174-9.839.126.154c9.928 32.58 15.978 67.641 11.511 120.237a2.462 2.462 0 01-2.44 2.242z"></path>
            <path
               fill="#3F3D56"
               d="M54.018 255.656l-.117-.246c-.056-.118-5.654-11.924-9.969-24.542a18.614 18.614 0 011.291-14.916 18.82 18.82 0 0111.998-9.226 18.84 18.84 0 0121.865 11.282c3.423 8.559 2.332 17.695 1.305 24.879l-.02.144-.131.063-26.222 12.562z"></path>
            <path
               fill="#2F2E41"
               d="M75.358 180.782H54.146v-9.246c4.656-1.849 9.212-3.422 11.966 0a9.246 9.246 0 019.246 9.246z"></path>
            <path
               fill="#2F2E41"
               d="M52.972 169.905c-12.68 0-16.23 15.894-16.23 24.861 0 5.001 2.262 6.789 5.816 7.394l1.255-6.693 2.94 6.981c.998.005 2.046-.014 3.133-.034l.997-2.052 2.223 2.015c8.901.013 16.096 1.311 16.096-7.611 0-8.967-3.113-24.861-16.23-24.861z"></path>
            <path
               fill="#CCC"
               d="M112.103 381.33H1.418a.577.577 0 010-1.153h110.685a.578.578 0 010 1.153z"></path>
            <path
               fill="#fff"
               d="M114.184 191.666H89.396a5.483 5.483 0 01-5.477-5.477V161.4a5.48 5.48 0 015.477-5.476h24.788a5.48 5.48 0 015.477 5.476v24.789a5.484 5.484 0 01-5.477 5.477z"></path>
            <path
               fill="#3F3D56"
               d="M114.184 191.666H89.396a5.483 5.483 0 01-5.477-5.477V161.4a5.48 5.48 0 015.477-5.476h24.788a5.48 5.48 0 015.477 5.476v24.789a5.484 5.484 0 01-5.477 5.477zm-24.788-34.589a4.326 4.326 0 00-4.324 4.323v24.789a4.327 4.327 0 004.324 4.324h24.788a4.324 4.324 0 003.056-1.268 4.324 4.324 0 001.268-3.056V161.4a4.323 4.323 0 00-1.268-3.055 4.324 4.324 0 00-3.056-1.268H89.396z"></path>
            <path
               fill="#3B5235"
               d="M109.861 181.577H93.719a1.73 1.73 0 01-1.73-1.729v-12.106a1.733 1.733 0 011.73-1.73h4.544a1.718 1.718 0 011.473.823l1.268 2.06h8.857a1.73 1.73 0 011.729 1.729v9.224a1.728 1.728 0 01-1.729 1.729z"></path>
            <path
               fill="#A0616A"
               d="M109.336 187.694a5.982 5.982 0 01-1.284 7.359 5.976 5.976 0 01-2.178 1.23 5.792 5.792 0 01-.57.143l-11.01 28.482a4.652 4.652 0 01-4.706 2.939l-.056-.01-23.864-7.185a5.123 5.123 0 01-3.051-2.505 5.128 5.128 0 014.098-7.518 5.134 5.134 0 012 .227l16.886 5.421 13.072-23.176a5.671 5.671 0 01-.294-.743 5.983 5.983 0 0110.957-4.664z"></path>
         </g>
         <defs>
            <clipPath id="clip0_543_5577">
               <path
                  fill="#fff"
                  d="M0 0H505.159V381.33H0z"
                  transform="translate(.841)"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
