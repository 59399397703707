/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="65"
         fill="none"
         viewBox="0 0 64 65">
         <rect
            width="64"
            height="64"
            y="0.485"
            fill="#3B5235"
            rx="32"></rect>
         <path
            fill="#FAFAFA"
            d="M42.667 39.152V25.818c0-2.89-4.884-5.333-10.667-5.333-5.783 0-10.667 2.443-10.667 5.333v13.334c0 2.89 4.884 5.333 10.667 5.333 5.783 0 10.667-2.443 10.667-5.333zM32 23.152c4.921 0 7.908 2.009 8 2.658-.092.666-3.079 2.675-8 2.675-4.921 0-7.908-2.01-8-2.659.092-.665 3.079-2.674 8-2.674zm-8 6.142c1.972 1.13 4.85 1.858 8 1.858 3.15 0 6.028-.728 8-1.858v3.183c-.092.665-3.079 2.675-8 2.675-4.921 0-7.908-2.01-8-2.667v-3.19zm0 9.858V35.96c1.972 1.13 4.85 1.857 8 1.857 3.15 0 6.028-.728 8-1.857v3.183c-.092.665-3.079 2.674-8 2.674-4.921 0-7.908-2.01-8-2.666z"></path>
      </svg>
   );
}

export default Icon;
