/** @format */

import React from "react";

const footerData = [
   {
      sections: "Links",
      links: [
         { title: "Home", id: "#home" },
         { title: "Services", id: "#services" },
         { title: "Testimonials", id: "#testimonials" },
      ],
   },

   {
      sections: "Contact",
      links: [{ title: "Email: akalyanandharaj@evolwe.tech ", id: "mailto:akalyanandharaj@evolwe.tech" }],
   },
];

export default function Footer() {
   return (
      <footer className="flex   lg:flex-row flex-col items-center lg:items-start justify-between py-4 lg:w-[90%] w-[100%]">
         <div className="w-[12rem] h-[12rem]">
            <img
               className="w-full h-full"
               src={require("../../../assets/images/Logo.png")}
            />
         </div>
         {footerData.map((section, index) => (
            <div
               key={index}
               className="flex flex-col mt-3 gap-1   lg:w-auto w-full  lg:items-start items-center">
               <h3 className="text-[1.3rem] font-bold font-sans">{section.sections}</h3>
               <div className="flex flex-col gap-2 lg:items-start items-center">
                  {section.links.map((link, index) => (
                     <a
                        href={link.id}
                        key={index}
                        className="text-[1.2rem] font-sans no-underline text-[#000]">
                        {link.title}
                     </a>
                  ))}
               </div>
            </div>
         ))}
      </footer>
   );
}
