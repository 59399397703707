/** @format */

import "App.css";
import { USERS } from "data/dummyData/users";
import { useEffect } from "react";
import Navbar from "components/LandingPage/Navbar";
import Home from "components/LandingPage/Home";
import Services from "components/LandingPage/Services";
import Testimonials from "components/LandingPage/Testimonials";
import Footer from "components/LandingPage/Footer";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..

const App = () => {
   useEffect(() => {
      AOS.init({
         disable: false,
         duration: 2000, // values from 0 to 3000, with step 50ms
         once: true, // whether animation should happen only once - while scrolling down
      });
   }, []);

   return (
      <div className="lg:px-[3rem] z-30 w-full">
         <Navbar />
         <Home />
         <Services />
         <Testimonials />
         <div className="bg-[#3B5235] my-14 w-full h-1"></div>
         <Footer />
      </div>
   );
};

export default App;
