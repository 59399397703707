/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="80%"
         height="100%"
         fill="none"
         viewBox="0 0 583 382">
         <g clipPath="url(#clip0_543_5764)">
            <path
               fill="#E6E6E6"
               d="M83.446 381.65c46.086 0 83.447-2.85 83.447-6.365s-37.361-6.364-83.447-6.364C37.36 368.921 0 371.77 0 375.285c0 3.515 37.36 6.365 83.446 6.365z"></path>
            <path
               fill="#9F616A"
               d="M110.473 96.114l-33.29-9.08c8.889-8.388 12.756-16.29 11.6-23.706h21.69v32.786z"></path>
            <path
               fill="#2F2E41"
               d="M114.004 351.343l-10.754 1.513-12.954-119.039-20.68 118.534-11.097.505V202.543h68.599l-13.114 148.8z"></path>
            <path
               fill="#2F2E41"
               d="M64.104 375.34a7.563 7.563 0 007.501-5.528l.785-11.721-1.583-8.313c-4.178-3.433-8.401-3.361-12.667 0l-1.36 17.412a7.562 7.562 0 007.324 8.15zM108.491 375.34a7.563 7.563 0 007.501-5.528l.786-11.721-1.583-8.313c-4.178-3.433-8.401-3.361-12.668 0l-1.36 17.412a7.562 7.562 0 007.324 8.15z"></path>
            <path
               fill="#9F616A"
               d="M105.933 78.964c10.586 0 19.168-8.581 19.168-19.167 0-10.586-8.582-19.167-19.168-19.167-10.586 0-19.167 8.581-19.167 19.167 0 10.586 8.581 19.167 19.167 19.167z"></path>
            <path
               fill="#E6E6E6"
               d="M130.145 205.065H57.006c9.48-43.932 9.453-79.315 1.902-107.93a7.646 7.646 0 014.65-9.09l19.677-7.567c5.908 7.628 14.244 10.96 27.238 6.052l10.311 2.115a10.596 10.596 0 018.456 11.079c-.759 11.191-.46 22.468-1.113 32.203-2.022 30.145-2.895 53.646 2.018 73.138z"></path>
            <path
               fill="#2F2E41"
               d="M95.845 34.577s11.097-12.61 24.212-.505l3.53 1.01-2.522 2.017s13.115 6.053 12.106 18.663c0 0-2.018-.505-3.026 0-1.009.504-1.514 6.053-1.514 6.053s-1.513-4.036-2.017-4.54c-.505-.505-7.566 5.548-19.168-3.53 0 0-3.53 9.078-7.566 8.07-4.035-1.01-9.583-.505-12.105 6.052 0 0-13.62-29.255 8.07-33.29z"></path>
            <path
               fill="#9F616A"
               d="M133.675 193.968l-6.474 8.679a8.498 8.498 0 00-.441 9.52 8.501 8.501 0 0010.516 3.422 8.507 8.507 0 005.14-6.512l1.852-11.578 3.531-49.936-2.522-25.22-16.141 6.053 4.539 21.689v43.883z"></path>
            <path
               fill="#E6E6E6"
               d="M127.118 134.449l19.672-9.08-1.009-7.146a29.34 29.34 0 00-18.732-27.355l-5.984-2.32 6.053 45.901z"></path>
            <path
               fill="#9F616A"
               d="M55.493 191.951l6.475 8.678a8.507 8.507 0 01-5.83 13.534 8.511 8.511 0 01-9.386-7.104L44.9 195.482l-3.53-49.936 2.522-25.221 16.14 6.053-4.54 21.69v43.883z"></path>
            <path
               fill="#E6E6E6"
               d="M62.05 132.431l-19.672-9.079 1.01-7.147A29.34 29.34 0 0162.12 88.85l5.983-2.32-6.053 45.901zM577.052 325.43H240.438a5.311 5.311 0 01-5.303-5.304V173.741a5.307 5.307 0 015.303-5.303h336.614a5.31 5.31 0 015.304 5.303v146.385a5.315 5.315 0 01-1.555 3.749 5.315 5.315 0 01-3.749 1.555zM240.438 170.559a3.19 3.19 0 00-2.249.933 3.19 3.19 0 00-.933 2.249v146.385a3.187 3.187 0 003.182 3.182h336.614a3.182 3.182 0 003.182-3.182V173.741a3.186 3.186 0 00-3.182-3.182H240.438z"></path>
            <path
               fill="#E6E6E6"
               d="M254.228 186.471a3.181 3.181 0 100 6.364h44.552a3.181 3.181 0 100-6.364h-44.552zM559.225 208.602v59.013H258.796v-31.072a312.352 312.352 0 016.286-3.951c9.019-5.519 18.673-10.681 29.194-12.594a44.047 44.047 0 0115.072-.188c5.279.871 10.332 2.69 15.335 4.516 4.934 1.806 9.88 3.669 15.04 4.716a44.039 44.039 0 0015.254.301c9.661-1.449 18.736-5.331 27.774-8.843 9.095-3.532 18.466-6.868 28.308-7.307 9.717-.439 19.315 1.856 28.535 4.704 18.799 5.801 37.993 14.406 57.898 8.505 20.213-5.984 38.805-23.257 61.205-18.133a.84.84 0 01.528.333z"></path>
            <path
               fill="#3B5235"
               d="M559.225 209.63a.905.905 0 01-1.029.452 33.522 33.522 0 00-8.141-.841c-22.034.389-38.974 18.126-60.518 20.779-19.425 2.39-37.708-7.056-55.972-11.992-9.489-2.565-19.274-3.926-29.039-2.182-9.69 1.725-18.785 5.688-27.917 9.207-8.793 3.387-17.931 6.535-27.452 6.692-10.468.176-19.895-4.139-29.604-7.426-5.049-1.712-10.23-3.067-15.58-3.198a47.093 47.093 0 00-15.353 2.377c-10.318 3.28-19.613 9.125-28.663 14.933a.798.798 0 01-.533.144.974.974 0 01-.818-.741.97.97 0 01.404-1.027l.414-.264a308.562 308.562 0 016.272-3.951c9-5.519 18.634-10.681 29.133-12.594a43.862 43.862 0 0115.04-.188c5.269.871 10.311 2.69 15.304 4.516 4.923 1.806 9.859 3.669 15.009 4.716a43.854 43.854 0 0015.222.301c9.64-1.449 18.697-5.331 27.716-8.843 9.075-3.532 18.427-6.868 28.249-7.307 9.696-.439 19.274 1.856 28.475 4.704 18.759 5.801 37.914 14.406 57.777 8.505 20.171-5.984 38.723-23.257 61.077-18.133.211.04.4.158.527.333a.972.972 0 010 1.028z"></path>
            <path
               fill="#3B5235"
               d="M300.191 224.965a4.39 4.39 0 10.001-8.78 4.39 4.39 0 00-.001 8.78zM411.206 219.32a4.39 4.39 0 100-8.78 4.39 4.39 0 000 8.78zM546.681 213.676a4.39 4.39 0 10-.002-8.782 4.39 4.39 0 00.002 8.782z"></path>
            <path
               fill="#E6E6E6"
               d="M297.719 292.546a3.185 3.185 0 00-3.182 3.183 3.181 3.181 0 003.182 3.182h44.552a3.18 3.18 0 003.182-3.182 3.184 3.184 0 00-3.182-3.183h-44.552z"></path>
            <path
               fill="#3B5235"
               d="M269.079 307.397c6.639 0 12.022-5.382 12.022-12.022 0-6.64-5.383-12.022-12.022-12.022-6.64 0-12.022 5.382-12.022 12.022 0 6.64 5.382 12.022 12.022 12.022z"></path>
            <path
               fill="#E6E6E6"
               d="M405.209 292.546a3.185 3.185 0 00-3.182 3.183 3.181 3.181 0 003.182 3.182h44.552a3.18 3.18 0 003.182-3.182 3.184 3.184 0 00-3.182-3.183h-44.552z"></path>
            <path
               fill="#3B5235"
               d="M376.569 307.397c6.639 0 12.022-5.382 12.022-12.022 0-6.64-5.383-12.022-12.022-12.022-6.64 0-12.022 5.382-12.022 12.022 0 6.64 5.382 12.022 12.022 12.022z"></path>
            <path
               fill="#E6E6E6"
               d="M512.699 292.546a3.185 3.185 0 00-3.182 3.183 3.181 3.181 0 003.182 3.182h44.552a3.18 3.18 0 003.182-3.182 3.184 3.184 0 00-3.182-3.183h-44.552z"></path>
            <path
               fill="#3B5235"
               d="M484.059 307.397c6.64 0 12.022-5.382 12.022-12.022 0-6.64-5.382-12.022-12.022-12.022-6.639 0-12.022 5.382-12.022 12.022 0 6.64 5.383 12.022 12.022 12.022z"></path>
            <path
               fill="#E6E6E6"
               d="M554.012 128.913H387.941c-3.237 0-5.861-1.869-5.865-4.172V88.646c.004-2.304 2.628-4.173 5.865-4.173h166.071c3.237 0 5.861 1.87 5.864 4.173v36.094c-.003 2.304-2.627 4.171-5.864 4.173zM387.941 86.141c-1.943 0-3.517 1.121-3.519 2.503v36.095c0 1.382 1.576 2.502 3.519 2.503h166.071c1.942 0 3.517-1.121 3.519-2.503V88.646c0-1.382-1.577-2.502-3.519-2.504l-166.071-.001z"></path>
            <path
               fill="#3B5235"
               d="M425.49 104.986a3.544 3.544 0 00-2.499 1.037 3.543 3.543 0 00-1.036 2.499v24.751a3.542 3.542 0 003.535 3.536h126.584a3.538 3.538 0 003.536-3.536v-24.751a3.54 3.54 0 00-3.536-3.536H425.49z"></path>
            <path
               fill="#fff"
               d="M443.17 129.03a7.779 7.779 0 100-15.558 7.78 7.78 0 000 15.558zM478.621 117.875a3.181 3.181 0 100 6.364h44.552a3.184 3.184 0 002.251-5.432 3.184 3.184 0 00-2.251-.932h-44.552z"></path>
            <path
               fill="#E6E6E6"
               d="M429.55 44.76H263.479c-3.238 0-5.861-1.869-5.865-4.172V4.492c.004-2.303 2.627-4.172 5.865-4.172H429.55c3.237 0 5.861 1.869 5.864 4.172v36.095c-.003 2.303-2.627 4.17-5.864 4.173zM263.479 1.987c-1.943 0-3.517 1.122-3.519 2.504v36.095c0 1.381 1.576 2.502 3.519 2.503H429.55c1.942 0 3.516-1.122 3.518-2.503V4.492c0-1.382-1.576-2.502-3.518-2.503l-166.071-.002z"></path>
            <path
               fill="#3B5235"
               d="M301.028 20.833a3.54 3.54 0 00-3.536 3.536v24.75a3.543 3.543 0 003.536 3.537h126.584a3.54 3.54 0 003.536-3.536V24.369a3.539 3.539 0 00-3.536-3.536H301.028z"></path>
            <path
               fill="#fff"
               d="M318.708 44.877a7.778 7.778 0 007.778-7.78 7.778 7.778 0 10-15.557 0 7.778 7.778 0 007.779 7.78zM354.159 33.721a3.182 3.182 0 000 6.365h44.552a3.18 3.18 0 003.182-3.182 3.181 3.181 0 00-3.182-3.183h-44.552z"></path>
         </g>
         <defs>
            <clipPath id="clip0_543_5764">
               <path
                  fill="#fff"
                  d="M0 0H582.356V381.33H0z"
                  transform="translate(0 .32)"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
