/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 668 448">
         <g clipPath="url(#clip0_528_4332)">
            <path
               fill="#2F2E41"
               d="M517.716 199.087l1.983-15.912a21.484 21.484 0 018.117-14.297 21.44 21.44 0 0115.838-4.364 21.456 21.456 0 0114.282 8.126 21.508 21.508 0 014.359 15.855l-1.983 15.912a2.884 2.884 0 01-3.218 2.506l-36.874-4.605a2.888 2.888 0 01-2.504-3.221z"></path>
            <path
               fill="#FFB8B8"
               d="M535.379 203.834c8.69 0 15.736-7.053 15.736-15.754 0-8.7-7.046-15.753-15.736-15.753-8.691 0-15.736 7.053-15.736 15.753 0 8.701 7.045 15.754 15.736 15.754z"></path>
            <path
               fill="#2F2E41"
               d="M514.501 183.819a17.02 17.02 0 016.426-11.304 16.984 16.984 0 0112.524-3.458l3.179.397a16.993 16.993 0 0111.291 6.433 17.026 17.026 0 013.454 12.537l-.039.318-6.718-.839-1.491-6.709-1.259 6.366-3.472-.434-.752-3.385-.635 3.212-22.548-2.816.04-.318z"></path>
            <path
               fill="#FFB8B8"
               d="M458.414 244.275a7.242 7.242 0 001.999 2.426 7.244 7.244 0 008.834.068l14.292 8.382 7.207-7.453-20.35-11.553a7.291 7.291 0 00-4.56-2.457 7.293 7.293 0 00-8.013 5.437 7.305 7.305 0 00.591 5.15z"></path>
            <path
               fill="#3F3D56"
               d="M505.258 259.336c-10.543 0-29.094-4.725-30.122-4.989l-.324-.083 2.92-14.799 28.336 5.603 15.394-21.701 17.673-1.799-.493.65c-.23.303-23.012 30.326-26.558 35.362-.894 1.271-3.45 1.756-6.826 1.756z"></path>
            <path
               fill="#3F3D56"
               d="M508.084 314.881l-.421-.202c-.09-.043-9.047-4.405-13.687-12.919-4.617-8.475 16.998-44.423 18.51-46.917l.022-11.417 7.775-21.015 9.916-5.611-8.513 19.887-13.602 78.194z"></path>
            <path
               fill="#FFB8B8"
               d="M498.15 437.583l-9.477-.001-4.509-36.595 13.988.001-.002 36.595z"></path>
            <path
               fill="#2F2E41"
               d="M500.567 446.779l-30.558-.001v-.387a11.91 11.91 0 013.484-8.419 11.885 11.885 0 018.41-3.488l18.664.001v12.294z"></path>
            <path
               fill="#FFB8B8"
               d="M599.946 429.268l-9.005 2.954-15.668-33.369 13.292-4.36 11.381 34.775z"></path>
            <path
               fill="#2F2E41"
               d="M605.103 437.254l-29.039 9.525-.12-.368a11.918 11.918 0 01.692-9.086 11.892 11.892 0 016.907-5.936l.001-.001 17.736-5.817 3.823 11.683zM581.597 423.285l-27.017-45.152-22.807-48.241-20.523 42.613-8.153 55.19-28.592.397.113-.447 43.755-174.046 34.267 5.125-1.559 22.644.929 1.323c7.741 11.009 15.745 22.391 11.154 34.661l13.014 38.683 28.806 64.412-23.387 2.838z"></path>
            <path
               fill="#3B5235"
               d="M519.938 291.32c-4.965 0-10.63-5.676-7.691-15.366l-.191-.138 7.06-31.223c-6.161-8.821 1.961-16.1 2.723-16.75l3.877-6.986 17.107-10.861 8.655 71.995-.128.122c-6.251 5.988-27.246 9.207-31.412 9.207z"></path>
            <path
               fill="#3F3D56"
               d="M554.972 307.565a8.373 8.373 0 01-2.02-.214c-.666-.167-1.743-1.015-3.407-6.44-6.617-21.567-14.429-86.459-8.753-92.809l.13-.145 9.318 1.555c.775-.573 4.84-3.413 8.591-2.919a5.692 5.692 0 013.896 2.305l.064.084 2.969 42.281 10.876 49.758-.261.118c-.633.288-14.214 6.426-21.403 6.426z"></path>
            <path
               fill="#FFB8B8"
               d="M548.705 291.04a7.251 7.251 0 006.848-8.765l13.642-9.406-3.688-9.693-19.078 13.554a7.288 7.288 0 00-4.156 3.092 7.304 7.304 0 001.523 9.571 7.284 7.284 0 004.909 1.647z"></path>
            <path
               fill="#3F3D56"
               d="M564.694 280.406l-12.152-8.921 17.081-23.317-13.108-23.156 5.865-16.787.378.723c.177.336 17.682 33.719 20.735 39.069 3.177 5.565-17.696 31.048-18.586 32.13l-.213.259z"></path>
            <path
               fill="#E6E6E6"
               d="M305.797 384.526l-10.341-4.36-7.095-51.9h-94.475l-7.69 51.688-9.252 4.632a2.195 2.195 0 00-1.156 2.472 2.196 2.196 0 002.138 1.694h127.019a2.19 2.19 0 001.392-.497 2.203 2.203 0 00.55-2.731 2.198 2.198 0 00-1.09-.998z"></path>
            <path
               fill="#CCC"
               d="M473.687 335.171H9.597a9.185 9.185 0 01-6.488-2.703 9.21 9.21 0 01-2.685-6.501v-65.514H482.86v65.514a9.212 9.212 0 01-2.685 6.501 9.187 9.187 0 01-6.488 2.703z"></path>
            <path
               fill="#3F3D56"
               d="M483.346 291.416H0V11.096a11.115 11.115 0 013.25-7.843A11.09 11.09 0 0111.084 0h461.177a11.09 11.09 0 017.834 3.253 11.118 11.118 0 013.251 7.843v280.32z"></path>
            <path
               fill="#fff"
               d="M454.477 271.071H28.868a8.555 8.555 0 01-8.545-8.554V28.9a8.569 8.569 0 012.506-6.046 8.55 8.55 0 016.04-2.509h425.608a8.55 8.55 0 016.04 2.509 8.566 8.566 0 012.505 6.046v233.617a8.555 8.555 0 01-8.545 8.554z"></path>
            <path
               fill="#CCC"
               d="M361.035 389.294H121.88a1.112 1.112 0 01-1.091-.805 1.086 1.086 0 01.569-1.249 1.07 1.07 0 01.478-.112h239.11a1.145 1.145 0 011.146.847 1.085 1.085 0 01-1.057 1.319z"></path>
            <path
               fill="#F2F2F2"
               d="M449.934 58.114H347.172v78.593h102.762V58.114z"></path>
            <path
               fill="#fff"
               d="M444.827 62.02h-92.549V132.8h92.549V62.019z"></path>
            <path
               fill="#3F3D56"
               d="M400.178 114.522l.037-.001a24.663 24.663 0 0016.514-7.563 24.725 24.725 0 00.378-33.806 24.664 24.664 0 00-16.34-7.933.56.56 0 00-.426.148.567.567 0 00-.182.408l-.539 48.176a.561.561 0 00.558.571z"></path>
            <path
               fill="#E6E6E6"
               d="M380.827 73.186c.192.002.375.08.51.218l16.566 16.96a.708.708 0 01.207.515l-.259 23.052a.692.692 0 01-.062.287.72.72 0 01-.718.428 24.818 24.818 0 01-12.76-4.69 24.86 24.86 0 01-8.383-10.712 24.886 24.886 0 014.347-25.812.72.72 0 01.525-.246h.027z"></path>
            <path
               fill="#3B5235"
               d="M397.672 65.022a.728.728 0 01.719.734l-.233 20.8a.727.727 0 01-.873.7.723.723 0 01-.368-.203l-14.456-14.8a.716.716 0 01-.206-.534.716.716 0 01.245-.517 24.872 24.872 0 0115.172-6.18zM390.225 124.512a4.442 4.442 0 01-2.782 4.068 4.422 4.422 0 01-4.818-1.016 4.443 4.443 0 01-.907-4.845 4.434 4.434 0 014.125-2.693 4.434 4.434 0 013.118 1.336 4.445 4.445 0 011.264 3.15z"></path>
            <path
               fill="#3F3D56"
               d="M402.887 124.654a4.442 4.442 0 01-2.782 4.068 4.422 4.422 0 01-4.818-1.016 4.443 4.443 0 01-.907-4.845 4.434 4.434 0 014.125-2.693 4.434 4.434 0 013.118 1.336 4.445 4.445 0 011.264 3.15z"></path>
            <path
               fill="#E6E6E6"
               d="M415.549 124.796a4.446 4.446 0 01-.775 2.457 4.424 4.424 0 01-4.57 1.835 4.43 4.43 0 01-3.443-3.525 4.43 4.43 0 01.281-2.56 4.431 4.431 0 014.125-2.693 4.43 4.43 0 013.117 1.336 4.446 4.446 0 011.265 3.15z"></path>
            <path
               fill="#F2F2F2"
               d="M448.852 149.076H346.091v78.592h102.761v-78.592z"></path>
            <path
               fill="#fff"
               d="M443.746 152.981h-92.549v70.782h92.549v-70.782z"></path>
            <path
               fill="#3F3D56"
               d="M366.899 176.245a3.845 3.845 0 003.843-3.847 3.844 3.844 0 00-3.843-3.847 3.844 3.844 0 00-3.842 3.847 3.845 3.845 0 003.842 3.847zM428.641 169.404a2.614 2.614 0 012.412 1.61 2.615 2.615 0 010 1.998 2.607 2.607 0 01-2.412 1.61h-40.415a2.61 2.61 0 010-5.218h40.415zm0-.639h-40.415a3.253 3.253 0 00-2.297.95c-.302.301-.541.66-.704 1.054a3.238 3.238 0 000 2.488 3.233 3.233 0 001.758 1.758c.394.163.817.247 1.243.246h40.415a3.23 3.23 0 002.298-.95 3.25 3.25 0 00-2.298-5.546z"></path>
            <path
               fill="#3B5235"
               d="M412.694 175.261H382.27a3.244 3.244 0 01-3.244-3.248 3.25 3.25 0 013.244-3.248h30.424a3.247 3.247 0 010 6.496z"></path>
            <path
               fill="#3F3D56"
               d="M366.899 192.219a3.845 3.845 0 003.843-3.847 3.844 3.844 0 00-3.843-3.847 3.844 3.844 0 00-3.842 3.847 3.845 3.845 0 003.842 3.847zM428.641 185.378a2.614 2.614 0 012.412 1.61 2.615 2.615 0 010 1.998 2.607 2.607 0 01-2.412 1.61h-40.415a2.607 2.607 0 01-2.606-2.609 2.607 2.607 0 012.606-2.609h40.415zm0-.639h-40.415a3.25 3.25 0 00-2.294 5.545 3.244 3.244 0 002.294.951h40.415a3.25 3.25 0 000-6.496z"></path>
            <path
               fill="#3B5235"
               d="M424.183 191.235H382.27a3.244 3.244 0 01-3.244-3.248 3.25 3.25 0 013.244-3.248h41.913a3.246 3.246 0 013.244 3.248 3.248 3.248 0 01-3.244 3.248z"></path>
            <path
               fill="#3F3D56"
               d="M366.899 208.193a3.844 3.844 0 003.843-3.847 3.844 3.844 0 00-3.843-3.847 3.844 3.844 0 00-3.842 3.847 3.844 3.844 0 003.842 3.847zM428.641 201.352a2.614 2.614 0 012.412 1.61 2.615 2.615 0 01-.566 2.846 2.615 2.615 0 01-1.846.762h-40.415a2.607 2.607 0 01-2.606-2.609 2.607 2.607 0 012.606-2.609h40.415zm0-.639h-40.415a3.245 3.245 0 00-3.244 3.248 3.244 3.244 0 003.244 3.248h40.415a3.248 3.248 0 000-6.496z"></path>
            <path
               fill="#3B5235"
               d="M399.291 207.209H382.27a3.234 3.234 0 01-2.297-.949 3.238 3.238 0 01-.704-1.055 3.238 3.238 0 010-2.488 3.244 3.244 0 013.001-2.004h17.021a3.248 3.248 0 010 6.496z"></path>
            <path
               fill="#F2F2F2"
               d="M323.375 72.019H36.278v147.297h287.097V72.019z"></path>
            <path
               fill="#fff"
               d="M313.805 79.338H45.848v132.659h267.957V79.338z"></path>
            <path
               fill="#3F3D56"
               d="M280.573 191.759H85.237a.611.611 0 01-.611-.612V97.42a.612.612 0 111.223 0v93.115h194.724a.61.61 0 01.432 1.044.611.611 0 01-.432.18z"></path>
            <path
               fill="#3B5235"
               d="M126.972 185.027h-17.767a1.815 1.815 0 01-1.814-1.817v-24.518a1.816 1.816 0 011.814-1.816h17.767a1.817 1.817 0 011.815 1.816v24.518a1.818 1.818 0 01-1.815 1.817zM159.371 185.027h-17.766a1.819 1.819 0 01-1.815-1.817v-47.773a1.818 1.818 0 011.815-1.816h17.766a1.814 1.814 0 011.815 1.816v47.773a1.817 1.817 0 01-1.815 1.817zM191.771 185.027h-17.767a1.815 1.815 0 01-1.814-1.817v-24.518a1.816 1.816 0 011.814-1.816h17.767a1.817 1.817 0 011.815 1.816v24.518a1.818 1.818 0 01-1.815 1.817zM224.17 185.027h-17.766a1.776 1.776 0 01-1.815-1.734v-57.118a1.778 1.778 0 011.815-1.734h17.766a1.78 1.78 0 011.815 1.734v57.118a1.781 1.781 0 01-1.815 1.734zM256.57 185.027h-17.767a1.814 1.814 0 01-1.814-1.816v-73.477a1.816 1.816 0 011.814-1.816h17.767a1.817 1.817 0 011.815 1.816v73.477a1.821 1.821 0 01-1.815 1.816z"></path>
            <path
               fill="#3F3D56"
               d="M118.089 149.532a3.67 3.67 0 003.668-3.672 3.67 3.67 0 00-3.668-3.671 3.67 3.67 0 00-3.668 3.671 3.67 3.67 0 003.668 3.672zM150.488 125.665a3.67 3.67 0 003.668-3.672 3.669 3.669 0 10-7.336 0 3.67 3.67 0 003.668 3.672zM182.888 149.532a3.67 3.67 0 003.667-3.672 3.67 3.67 0 00-3.667-3.671 3.67 3.67 0 00-3.668 3.671 3.67 3.67 0 003.668 3.672zM215.287 114.038a3.67 3.67 0 003.668-3.672 3.67 3.67 0 00-3.668-3.672 3.67 3.67 0 00-3.668 3.672 3.67 3.67 0 003.668 3.672zM247.687 100.574a3.67 3.67 0 003.668-3.672 3.67 3.67 0 00-3.668-3.672 3.67 3.67 0 00-3.668 3.672 3.67 3.67 0 003.668 3.672z"></path>
            <path
               fill="#3F3D56"
               d="M182.965 146.683l-32.477-24.352-32.033 24.019-.733-.979 32.766-24.57 32.322 24.237 32.12-35.188.128-.052 32.4-13.068.457 1.135-32.271 13.016-32.679 35.802z"></path>
            <path
               fill="#CCC"
               d="M667.291 448H397.27a.709.709 0 010-1.419h270.021a.709.709 0 010 1.419z"></path>
         </g>
         <defs>
            <clipPath id="clip0_528_4332">
               <path
                  fill="#fff"
                  d="M0 0H668V448H0z"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
