/** @format */

import React from "react";
import { Drawer } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const paths = [
   {
      title: "Home",
      id: "#home",
   },
   {
      title: "Services",
      id: "#services",
   },
   {
      title: "Testimonials",
      id: "#testimonials",
   },
];

export default function Navbar() {
   const [showDrawer, setshowDrawer] = useState(false);
   const toggleDrawer = () => setshowDrawer(!showDrawer);

   return (
      <div
         data-aos="fade"
         className="flex items-center justify-between w-full ">
         <Drawer
            open={showDrawer}
            onClose={toggleDrawer}
            anchor="right">
            <div className="px-[2rem] flex  items-center flex-col pt-5  gap-4">
               <div className="w-[7rem] h-[7rem] ">
                  <img
                     className="w-full h-full"
                     src={require("../../../assets/images/Logo.png")}
                  />
               </div>
               {paths.map((item, index) => {
                  return (
                     <a
                        onClick={toggleDrawer}
                        href={item.id}
                        className="no-underline text-[#000] text-[1.5rem] hover:font-bold transition duration-300 ease-in-out">
                        {item.title}
                     </a>
                  );
               })}
            </div>
         </Drawer>
         <div className="flex items-center  w-full  justify-between">
            <div className="w-[7rem] h-[7rem] ">
               <img
                  className="w-full h-full"
                  src={require("../../../assets/images/Logo.png")}
               />
            </div>
            <div className="hidden lg:flex lg:w-[80%] gap-10">
               {paths.map((path, index) => (
                  <a
                     href={path.id}
                     key={index}
                     className="text-[1.5rem] hover:text-[#2ECC71] font-sans no-underline text-[#000] font-bold ml-[2rem]">
                     {path.title}
                  </a>
               ))}
            </div>
            <div
               onClick={toggleDrawer}
               className="lg:hidden  mr-4">
               <MenuIcon />
            </div>
            <div className="hidden lg:flex">
               <a
                  href="mailto:akalyanandharaj@evolwe.tech"
                  className="text-[#000] font-bold text-[1.5rem]  font-sans no-underline">
                  {" "}
                  Contact us
               </a>
            </div>
         </div>
      </div>
   );
}
