/** @format */

import React from "react";
import SectionTitle from "../SectionTitle";
import ServiceCard from "../ServiceCard";
import Recommendation from "assets/vectors/recommendation";
import Geo from "assets/vectors/geo";
import Analysis from "assets/vectors/analysis";
import Learning from "assets/vectors/learning";
import Language from "assets/vectors/language";
import Tools from "assets/icons/Tools";
import Globe from "assets/icons/Globe";
import Translate from "assets/icons/Translate";
import Diagram from "assets/icons/Diagram";
import Database from "assets/icons/Database";
import RightVector from "assets/vectors/RightDecorator";
import LeftVector from "assets/vectors/LeftDecorator";

const serviceData = [
   {
      title: "Building recommendation systems",
      description:
         "Crafting cutting-edge recommendation systems that elevate user experiences to new heights.It enhance business by boosting sales/optimizing user engagement depending on the sectors.",
      vector: <Recommendation />,
      icon: <Tools />,
   },
   {
      title: "Geospatial Analysis",
      description:
         "Redefine user experiences by delivering precise location-based insights. Whether optimizing logistics, enhancing navigation, or streamlining urban planning, our solutions drive efficiency, revenue growth, and user satisfaction.",
      vector: <Geo />,
      icon: <Globe />,
   },
   {
      title: "Natural Language Processing",
      description:
         "Transform user interactions with sophisticated language understanding solutions. From analyzing sentiments, developing chatbots, or enabling contextual content extraction, our advanced NLP systems enhance user experiences.",
      vector: <Language />,
      icon: <Translate />,
   },
   {
      title: "Reinforcement learning algorithms",
      description: "Develop cutting-edge demand pricing algorithms employing state-of-the-art reinforcement learning techniques to optimize revenue across various sectors.",
      vector: <Learning />,
      icon: <Diagram />,
   },
   {
      title: "Data Analysis",
      description:
         "We leverage advanced data analysis to transform user experiences, uncovering patterns and optimizing processes across diverse domains. Our expertise drives efficiency, innovation, and informed decision-making, ensuring businesses thrive in a data-centric landscape.",
      vector: <Analysis />,
      icon: <Database />,
   },
];

export default function Services() {
   return (
      <div
         id="services"
         className="mt-4">
         <SectionTitle title={"Services"} />
         {/* <div className="absolute left-0 top-[90rem] z-20">
            <LeftVector />
         </div>
         <div className="absolute right-0 top-[150rem] z-20">
            <RightVector />
         </div> */}
         {serviceData.map((service, index) => (
            <ServiceCard
               key={index}
               title={service.title}
               description={service.description}
               vector={service.vector}
               icon={service.icon}
               reverse={index % 2 === 0 ? false : true}
            />
         ))}
      </div>
   );
}
