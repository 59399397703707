/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="65"
         fill="none"
         viewBox="0 0 64 65">
         <rect
            width="64"
            height="64"
            y="0.165"
            fill="#3B5235"
            rx="32"></rect>
         <path
            fill="#FAFAFA"
            d="M44.947 43.218l-1.894 1.894a1.334 1.334 0 01-1.88 0l-15.84-15.814a5.08 5.08 0 01-1.333.2 5.333 5.333 0 01-5.04-7.066l3.387 3.386.706-.706 1.894-1.894.706-.706-3.386-3.387a5.333 5.333 0 017.066 5.04c-.007.451-.074.9-.2 1.333l15.814 15.84a1.334 1.334 0 010 1.88zm-25.894-1.88a1.333 1.333 0 000 1.88l1.894 1.894a1.334 1.334 0 001.88 0l7.293-7.28-3.773-3.774m16.32-15.226l-5.334 2.666v2.667l-2.893 2.893 2.667 2.667L40 26.832h2.667l2.666-5.334-2.666-2.666z"></path>
      </svg>
   );
}

export default Icon;
