/** @format */

import React from "react";
import { useIsSmallerDevice } from "hooks/useIsSmallerDevice";

export default function ServiceCard({ title, description, vector, reverse, icon }) {
   const isSmallerDevice = useIsSmallerDevice();

   const getFlexDirection = () => {
      if (!isSmallerDevice) {
         if (reverse) {
            return "row-reverse";
         } else {
            return "row";
         }
      }
      return "column";
   };

   return (
      <div
         style={{ flexDirection: getFlexDirection() }}
         className="py-3 flex   justify-center items-center z-20 mt-5">
         <div
            data-aos="fade"
            className="flex flex-col justify-between z-20 items-center w-full lg:w-[60%]">
            <div className="flex flex-col   items-center gap-3">
               {icon}
               <h5 className="font-sans text-[1.5rem] lg:text-[2rem] text-center font-bold">{title}</h5>
            </div>
            <p className="text-center z-20 w-[90%] mt-5 font-sans text-[1.5rem] lg:text-[2rem]">{description}</p>
         </div>
         <div
            data-aos="fade"
            className="lg:w-[40%] w-full flex items-center justify-center z-20">
            {vector}
         </div>
      </div>
   );
}
