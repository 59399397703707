/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="80%"
         height="100%"
         fill="none"
         viewBox="0 0 540 382">
         <g clipPath="url(#clip0_543_5725)">
            <path
               fill="#F1F1F1"
               d="M36.234 381.32l-.66-1.219c8.84-4.78 19.839-10.729 25.584-20.491 5.664-9.623 5.791-22.691.332-34.104-4.646-9.713-13.18-18.415-24.68-25.166-2.268-1.332-4.649-2.593-6.952-3.814-5.566-2.95-11.321-6-16.067-10.328-6.536-5.961-11.482-15.964-7.796-24.873a16.53 16.53 0 0114.33-10.027l.079 1.384a15.149 15.149 0 00-13.132 9.182c-3.425 8.279 1.275 17.675 7.453 23.31 4.616 4.209 10.293 7.218 15.783 10.127 2.315 1.228 4.71 2.497 7.004 3.844 11.74 6.891 20.463 15.799 25.228 25.763 5.656 11.823 5.507 25.39-.387 35.405-5.941 10.095-17.13 16.146-26.12 21.007z"></path>
            <path
               fill="#F1F1F1"
               d="M29.11 258.604c6.698 0 12.128-2.638 12.128-5.891s-5.43-5.891-12.128-5.891-12.128 2.638-12.128 5.891 5.43 5.891 12.128 5.891zM38.812 297.413c6.698 0 12.128-2.637 12.128-5.891 0-3.253-5.43-5.89-12.128-5.89s-12.128 2.637-12.128 5.89c0 3.254 5.43 5.891 12.128 5.891zM16.636 303.65c6.698 0 12.128-2.637 12.128-5.89 0-3.254-5.43-5.891-12.128-5.891s-12.128 2.637-12.128 5.891c0 3.253 5.43 5.89 12.128 5.89zM51.287 336.222c6.698 0 12.128-2.637 12.128-5.89 0-3.254-5.43-5.891-12.128-5.891s-12.128 2.637-12.128 5.891c0 3.253 5.43 5.89 12.128 5.89zM75.543 336.222c6.698 0 12.127-2.637 12.127-5.89 0-3.254-5.43-5.891-12.127-5.891-6.699 0-12.128 2.637-12.128 5.891 0 3.253 5.43 5.89 12.128 5.89z"></path>
            <path
               fill="#E6E6E6"
               d="M522.312 321.553H324.916a17.668 17.668 0 01-17.647-17.647V17.637A17.667 17.667 0 01324.916-.01h197.396a17.669 17.669 0 0117.647 17.647v286.269a17.668 17.668 0 01-17.647 17.647z"></path>
            <path
               fill="#fff"
               d="M522.66 310.294H324.568a5.444 5.444 0 01-5.439-5.44V16.689a5.442 5.442 0 015.439-5.44H522.66a5.448 5.448 0 015.44 5.44v288.165a5.449 5.449 0 01-5.44 5.44zM422.863 6.896a1.502 1.502 0 100-3.004 1.502 1.502 0 000 3.004z"></path>
            <path
               fill="#3F3D56"
               d="M503.458 201.001H343.77a.499.499 0 01-.5-.499v-76.539a.5.5 0 011 0v76.039h159.188a.501.501 0 01.352.852.496.496 0 01-.352.147z"></path>
            <path
               fill="#3B5235"
               d="M377.888 195.504h-14.524a1.483 1.483 0 01-1.483-1.483v-20.022a1.483 1.483 0 011.483-1.483h14.524a1.484 1.484 0 011.484 1.483v20.022a1.483 1.483 0 01-1.484 1.483zM404.375 195.504h-14.524a1.487 1.487 0 01-1.484-1.483v-39.012a1.488 1.488 0 011.484-1.484h14.524a1.488 1.488 0 011.484 1.484v39.012a1.487 1.487 0 01-1.484 1.483zM430.862 195.504h-14.524a1.484 1.484 0 01-1.484-1.483v-20.022a1.487 1.487 0 011.484-1.483h14.524a1.483 1.483 0 011.483 1.483v20.022a1.483 1.483 0 01-1.483 1.483zM457.349 195.504h-14.525a1.455 1.455 0 01-1.483-1.416v-46.643a1.453 1.453 0 011.483-1.416h14.525a1.455 1.455 0 011.483 1.416v46.643a1.455 1.455 0 01-1.483 1.416zM483.835 195.504h-14.524a1.483 1.483 0 01-1.483-1.483v-60.002a1.483 1.483 0 011.483-1.483h14.524a1.484 1.484 0 011.484 1.483v60.002a1.487 1.487 0 01-1.484 1.483z"></path>
            <path
               fill="#3F3D56"
               d="M370.626 166.519a3 3 0 100-5.999 3 3 0 000 5.999zM397.113 147.028a2.999 2.999 0 100-5.997 2.999 2.999 0 000 5.997zM423.6 166.519a2.999 2.999 0 10-.001-5.997 2.999 2.999 0 00.001 5.997zM450.087 137.533a2.998 2.998 0 100-5.995 2.998 2.998 0 000 5.995zM476.573 126.539a3 3 0 100-5.999 3 3 0 000 5.999z"></path>
            <path
               fill="#3F3D56"
               d="M423.663 164.192l-26.55-19.886-26.187 19.614-.6-.8 26.787-20.064 26.424 19.793 26.257-28.735.106-.043 26.487-10.672.373.927-26.381 10.63-26.716 29.236z"></path>
            <path
               fill="#9F616A"
               d="M190.324 155.564a6.91 6.91 0 00-5.313 2.483l-31.054-.971-1.215-5.235-16.071 7.843 2.079 14.338 47.327-6.06a6.926 6.926 0 0010.803-3.226 6.914 6.914 0 00-.918-6.273 6.933 6.933 0 00-5.638-2.899zM132.802 372.203h8.497l4.042-32.772-12.54.001.001 32.771z"></path>
            <path
               fill="#2F2E41"
               d="M130.635 369.429l16.732-.001h.001a10.663 10.663 0 0110.663 10.663v.347l-27.396.001v-11.01z"></path>
            <path
               fill="#9F616A"
               d="M100.743 365.446l8.162 2.359 12.982-30.361-12.047-3.481-9.097 31.483z"></path>
            <path
               fill="#2F2E41"
               d="M99.43 362.18l16.075 4.645a10.665 10.665 0 017.284 13.204l-.096.333-26.319-7.605 3.057-10.577zM122.369 229.899s-3.022 8.432 1.745 19.936l3.679 47.38 1.088 67.385h15.49l9.374-68.694 5.629-62.288-37.005-3.719z"></path>
            <path
               fill="#2F2E41"
               d="M113.657 229.899s-3.023 8.432 1.745 19.936l3.679 47.38-18.316 64.177h15.489l28.779-65.486 5.629-62.288-37.005-3.719z"></path>
            <path
               fill="#9F616A"
               d="M159.095 121.226c9.401 0 17.022-7.621 17.022-17.021 0-9.401-7.621-17.022-17.022-17.022-9.4 0-17.021 7.621-17.021 17.022 0 9.4 7.621 17.021 17.021 17.021z"></path>
            <path
               fill="#CBCBCB"
               d="M161.372 145.609l-3.449-16.027-17.564-9.973-3.562 4.986s-25.132 6.605-22.093 35.464c3.04 28.859 1.654 42.719 1.654 42.719l-6.931 31.88s2.134.366 51.097 8.92l2.079-69.303-1.231-28.666z"></path>
            <path
               fill="#000"
               d="M144.333 301.573l-15.95 32.218v9.235l15.95-41.453z"
               opacity="0.1"></path>
            <path
               fill="#2F2E41"
               d="M140.743 109.589c1.623 4.346 7.764 4.948 9.93.845a5.59 5.59 0 00.41-.997c.593-1.97.22-4.141 1.316-6.026a3.178 3.178 0 011.112-1.148c3.642-2.149 7.228 4.668 10.615 2.761 2.077-1.169 1.633-4.566 3.481-6.071 2.44-1.987 5.964.972 9.091.634 3.481-.376 5.127-4.784 4.371-8.202-.923-4.168-4.184-7.55-8.01-9.444-3.826-1.892-8.174-2.464-12.442-2.57-4.849-.12-9.922.394-14.022 2.986-4.986 3.152-7.795 9.02-8.417 14.885-.378 3.568 1.24 8.797 2.565 12.347z"></path>
            <path
               fill="#3F3D56"
               d="M224.152 226.423l.075-.001a50.515 50.515 0 0047.869-49.876 50.509 50.509 0 00-46.739-50.937 1.152 1.152 0 00-.872.303 1.15 1.15 0 00-.373.835l-1.104 98.51a1.15 1.15 0 00.331.819c.214.219.506.344.813.347z"></path>
            <path
               fill="#E6E6E6"
               d="M184.538 141.899c.393.005.769.165 1.044.446l33.913 34.681a1.48 1.48 0 01.422 1.052l-.529 47.138a1.451 1.451 0 01-.479 1.073 1.486 1.486 0 01-1.118.389 50.842 50.842 0 01-34.383-84.276 1.479 1.479 0 011.075-.503h.055z"></path>
            <path
               fill="#3B5235"
               d="M219.023 125.207a1.492 1.492 0 011.356.909c.078.187.117.388.114.59l-.477 42.531a1.479 1.479 0 01-2.539 1.018l-29.594-30.264a1.49 1.49 0 01-.422-1.054 1.474 1.474 0 01.502-1.095 50.946 50.946 0 0130.968-12.633.935.935 0 01.092-.002z"></path>
            <path
               fill="#9F616A"
               d="M172.998 174.968a6.919 6.919 0 00-5.313 2.484l-31.054-.971-1.215-5.235-16.071 7.842 2.079 14.338 47.327-6.059a6.931 6.931 0 009.05-.476 6.924 6.924 0 002.054-5.998 6.92 6.92 0 00-3.686-5.158 6.939 6.939 0 00-3.171-.767z"></path>
            <path
               fill="#CBCBCB"
               d="M116.727 184.994l-.863-42.713a11.307 11.307 0 0111.194-11.54 11.32 11.32 0 018.072 3.272 11.31 11.31 0 013.353 8.039v31.216l-21.756 11.726zM264.735 381.153H.693a.693.693 0 110-1.386h264.042a.693.693 0 110 1.386z"></path>
         </g>
         <defs>
            <clipPath id="clip0_543_5725">
               <path
                  fill="#fff"
                  d="M0 0H539.959V381.33H0z"
                  transform="translate(0 -.01)"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default Icon;
