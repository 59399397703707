/** @format */

import React from "react";
import Hero from "assets/vectors/hero";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RightVector from "assets/vectors/RightDecorator";
import { useIsSmallerDevice } from "hooks/useIsSmallerDevice";

export default function Home() {
   const isSmallerDevice = useIsSmallerDevice();

   const goToServices = () => {
      window.location.href = "#services";
   };

   return (
      <div className="lg:h-[80vh] z-20   mt-10 gap-10 lg:gap-2 lg:mt-3 flex lg:flex-row flex-col items-center justify-center">
         <div
            data-aos="fade"
            className="lg:w-[40%] w-[90%] lg:mt-[5rem]  lg:h-full flex flex-row justify-center items-center">
            <Hero />
         </div>
         <div
            data-aos="fade-left"
            className="h-full items-center   justify-center flex-col flex lg:w-[50%]">
            <div className="w-[100%] ">
               <h1
                  style={{ fontSize: isSmallerDevice ? "2.5rem" : "4.5rem", textAlign: isSmallerDevice ? "center" : "left" }}
                  className="font-montserrat  font-bolder   text-[#3B5235]">
                  Driving <br /> decisions <br /> through data
               </h1>
               <div
                  style={{ fontSize: isSmallerDevice ? "1rem" : "1.7rem", textAlign: isSmallerDevice ? "center" : "left" }}
                  className="font-sans lg:w-[80%] text-[1.7rem]">
                  Turning information into meaningful patterns
               </div>
               <div className="w-full mt-6 flex lg:justify-start justify-center">
                  <button
                     onClick={goToServices}
                     className="font-sans text-[1.2rem] text-white bg-[#3B5235] px-4 py-2 rounded-md">
                     Explore Our Services{" "}
                     <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ top: 2 }}
                     />
                  </button>
               </div>
            </div>
         </div>
         {/* <div className="absolute z-10 right-0 top-[28rem]">
            <RightVector />
         </div> */}
      </div>
   );
}
