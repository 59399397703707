/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="65"
         fill="none"
         viewBox="0 0 64 65">
         <rect
            width="64"
            height="64"
            y="0.825"
            fill="#3B5235"
            rx="32"></rect>
         <path
            fill="#FAFAFA"
            d="M45.896 43.925L40.27 30.3a1.376 1.376 0 00-2.542 0l-5.625 13.625a1.375 1.375 0 102.542 1.05L35.79 42.2h6.417l1.146 2.774a1.375 1.375 0 102.541-1.049zm-8.97-4.475L39 34.428l2.073 5.022h-4.146zm-4.186-1.193a1.375 1.375 0 00-.306-1.918c-.012-.01-.937-.696-2.28-2.17 2.478-3.356 3.882-7.173 4.454-8.969h2.017a1.375 1.375 0 000-2.75h-7.25V21.2a1.375 1.375 0 00-2.75 0v1.25h-7.25a1.375 1.375 0 000 2.75h12.328c-.595 1.684-1.69 4.344-3.362 6.773-1.963-2.605-2.692-4.291-2.698-4.305a1.375 1.375 0 00-2.536 1.063c.036.086.91 2.139 3.304 5.245l.17.22c-2.452 2.772-4.858 4.49-5.865 5.046a1.375 1.375 0 001.317 2.414c.135-.073 3.038-1.68 6.352-5.35 1.407 1.506 2.375 2.216 2.433 2.257a1.374 1.374 0 001.922-.306z"></path>
      </svg>
   );
}

export default Icon;
