/** @format */

import React from "react";

export default function SectionTitle({ title }) {
   return (
      <div
         data-aos="zoom-in"
         className="w-full text-[3rem] text-[#3B5235] text-center font-montserrat font-bolder">
         {title}
      </div>
   );
}
