/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="65"
         fill="none"
         viewBox="0 0 64 65">
         <rect
            width="64"
            height="64"
            y="0.155"
            fill="#3B5235"
            rx="32"></rect>
         <path
            fill="#FAFAFA"
            d="M25 24.155a1 1 0 100-2 1 1 0 000 2z"></path>
         <path
            fill="#FAFAFA"
            d="M43 38.155v-4a2 2 0 00-2-2h-8v-4h9a2 2 0 002-2v-6a2 2 0 00-2-2H22a2 2 0 00-2 2v6a2 2 0 002 2h9v4h-8a2 2 0 00-2 2v4h-1a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2h-1v-4h8v4h-1a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2h-1v-4h8v4h-1a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2h-1zm-19 6h-4v-4h4v4zm10-4v4h-4v-4h4zm-12-14v-6h20v6H22zm22 18h-4v-4h4v4z"></path>
      </svg>
   );
}

export default Icon;
